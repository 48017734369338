import { useState, useEffect } from 'react';

export const useAspectRatioCalculator = ({ ref, x, y, offsetX, offsetY }) => {
  const horizontalPixelOffset = offsetX || 0;
  const verticalPixelOffset = offsetY || 0;
  const [aspectRatio, setAspectRatio] = useState({ x, y });

  useEffect(() => {
    if (x > 0 && y > 0 && ref?.current) {
      const aspectRatioAsPercentage = (y * 100) / x;
      const sliderPixelWidth = ref.current.clientWidth;
      const sliderPixelWidthWithoutSpacings = sliderPixelWidth - horizontalPixelOffset;
      const sliderPixelHeight =
        (aspectRatioAsPercentage * (sliderPixelWidthWithoutSpacings + verticalPixelOffset)) / 100;

      setAspectRatio({ x: sliderPixelWidth, y: sliderPixelHeight });
    }
  }, [ref, x, y, horizontalPixelOffset, verticalPixelOffset]);

  return aspectRatio;
};
