import Image from 'next/image';
import styled from 'styled-components';
import { rem } from 'polished';
import { up } from 'styled-breakpoints';

import Link from '../Link';
import { contentGridQuartersModifier } from 'src/enums/contentGridQuartersModifier';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { determineFontSize } from 'src/utils/fonts';

export const ImagesCarousel = styled.div``;

export const Article = styled.div`
  position: relative;
  overflow: hidden;

  ${({ disableBannerRounding }) => !disableBannerRounding && `border-radius: ${rem(30)};`}
`;

export const ArticleImg = styled(Image)`
  ${(p) => p.theme.helpers.transition(['transform'])};
  height: auto;
  display: block;
  object-fit: cover;
  width: 100%;
`;

export const ArticleDescription = styled.figcaption`
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[10]};
  backdrop-filter: blur(10px);
  bottom: 0;
  padding: ${rem(16)};

  position: absolute;
  text-align: center;
  width: 100%;

  ${up(breakpointsKeys.TABLET)} {
    font-size: ${(p) => (p.isSinglePerPage ? '1.75em' : '1.25em')};
  }

  ${up(breakpointsKeys.DESKTOP)} {
    font-size: ${(p) =>
      p.slidesPerView && determineFontSize(contentGridQuartersModifier.SINGLE_COLUMN, p.slidesPerView)};

    .${contentGridQuartersModifier.THREE_COLUMNS} &&& {
      font-size: ${(p) =>
        p.slidesPerView && determineFontSize(contentGridQuartersModifier.THREE_COLUMNS, p.slidesPerView)};
    }

    .${contentGridQuartersModifier.TWO_COLUMNS} &&& {
      font-size: ${(p) =>
        p.slidesPerView && determineFontSize(contentGridQuartersModifier.TWO_COLUMNS, p.slidesPerView)};
    }
  }
`;

export const LinkWrapper = styled(Link)`
  width: 100%;
  height: 100%;

  & > * {
    width: inherit;
  }

  ${up(breakpointsKeys.TABLET)} {
    &:hover {
      ${ArticleImg} {
        transform: scale(1.05);
      }
    }
  }
`;
