import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as articlesTypes from 'config/articlesTypes';
import useArticlesList from 'src/hooks/useArticlesList';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import { breakpointsKeys } from 'src/enums/breakpoints';
import * as S from './styles';

const defaultProps = {
  tags: [],
  articlesPerPage: 1,
  take: 1,
  skip: 0,
  infinite: false,
  autoPlay: false,
  articlesType: articlesTypes.ACTIVE,
  skipLoader: true,
  sortingByPersonalizationTags: false,
  isPersonalizationEnabled: false,
  initialArticles: [],
  CMSSpacing: {},
};

export const ArticlesCarousel = ({
  categories: initialCategories,
  tags,
  take,
  skip,
  infinite,
  autoPlay,
  articlesPerPage,
  articlesType,
  skipLoader,
  user,
  isPersonalizationEnabled,
  sortingByPersonalizationTags,
  initialArticles,
  CMSSpacing,
  disableBannerRounding,
} = defaultProps) => {
  const { events } = useRouter();
  const isMobile = breakpointsKeys.MOBILE === useBreakpointDetector();

  const categories = initialCategories.join('|');
  const slidesPerPageOnMobile = 1.15; // ONNINB2B-606: ArticlesCarousel should display small part of another banner
  const isSinglePerPage = articlesPerPage === 1;
  const thumbWidth = isSinglePerPage ? 960 : 640;
  const [loadArticles, { articles, loading: articleLoading }] = useArticlesList(articlesType, {
    categories,
    tags,
    take,
    skip,
    thumbWidth,
    ssr: false,
    clearImage: isSinglePerPage,
    user,
    sortingByPersonalizationTags,
    paginationPageIdx: 1,
    isPersonalizationEnabled,
    articlesCount: take,
  });

  const isServerSideArticles = !!initialArticles.length;

  useEffect(() => {
    if (articleLoading) {
      return;
    }
    events.on('routeChangeComplete', loadArticles);

    return () => {
      events.off('routeChangeComplete', loadArticles);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ArticlesCarousel
      slidesPerPage={+articlesPerPage}
      infinite={infinite}
      autoPlay={autoPlay}
      showArrows={!isMobile}
      slidesPerPageOnMobile={slidesPerPageOnMobile}
      skipLoader={skipLoader}
      CMSSpacing={CMSSpacing}
      disableBannerRounding={disableBannerRounding}
      slides={
        isServerSideArticles
          ? initialArticles.map((article) => {
              const { title, thumbnail, slug, layout, showTitleInCarousel } = article;

              return {
                imgUrl: thumbnail?.url || null,
                slug: `/${slug}`,
                hideTitle: !showTitleInCarousel,
                title,
                layout,
              };
            })
          : articles.map((article) => {
              const { title, thumbnail, slug, layout, showTitleInCarousel } = article;

              return {
                imgUrl: thumbnail?.url || null,
                slug: `/${slug}`,
                hideTitle: !showTitleInCarousel,
                title,
                layout,
              };
            })
      }
    />
  );
};

ArticlesCarousel.defaultProps = defaultProps;
