import { useEffect, useRef, useState } from 'react';
import useAspectRatioCalculator from 'src/hooks/useAspectRatioCalculator';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useLanguage from 'src/hooks/useLanguage';
import { createNextLinkProps } from 'src/utils/createNextLinkProps';
import Slider from '../Slider';
import { breakpointsKeys } from 'src/enums/breakpoints';
import * as S from './styles';

const desktopSingleSlideOffset = 30;
const mobileSingleSlideOffset = 10;

const singleSlideAspectRatio = {
  x: 16,
  y: 9,
  offset: 0,
};

const defaultProps = {
  slidesPerPage: 1,
  slidesPerPageOnMobile: 1,
  infinite: false,
  autoPlay: false,
  skipLoader: false,
  showArrows: true,
};

export const ImagesCarousel = ({
  slides,
  infinite,
  autoPlay,
  slidesPerPage,
  slidesPerPageOnMobile,
  skipLoader,
  showArrows,
  disableBannerRounding = false,
  ...rest
} = defaultProps) => {
  const tabletArticlesPerPage = slidesPerPage - 1 === 0 ? 1 : slidesPerPage - 1;
  const slidesPerView = {
    mobile: slidesPerPageOnMobile,
    tablet: tabletArticlesPerPage,
    desktop: slidesPerPage,
    hd: slidesPerPage,
  };
  const ImagesCarouselRef = useRef();
  const carouselWrapperRef = useRef();
  const currentBreakpoint = useBreakpointDetector();
  const isSinglePerPage = slidesPerPage === 1;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [language] = useLanguage();
  const isMobile = breakpointsKeys.MOBILE === currentBreakpoint;
  const slideOffset = isMobile ? mobileSingleSlideOffset : desktopSingleSlideOffset;

  const nonEmptySlides = slides
    .reduce((nonEmptySlidesList, slide) => {
      if (!slide.imgUrl) {
        return nonEmptySlidesList;
      }

      return [...nonEmptySlidesList, slide];
    }, [])
    .filter((slide) => !!slide);

  const [loaded, setLoaded] = useState(() => {
    const initialLoad = [...Array(slidesPerView[currentBreakpoint] + 1)].map(() => true);

    return initialLoad;
  });

  const tabletAspectRatio = useAspectRatioCalculator({
    ref: ImagesCarouselRef,
    x: singleSlideAspectRatio.x * tabletArticlesPerPage,
    y: singleSlideAspectRatio.y,
    offsetX: desktopSingleSlideOffset * (tabletArticlesPerPage - 1),
  });

  const desktopAspectRatio = useAspectRatioCalculator({
    ref: ImagesCarouselRef,
    x: singleSlideAspectRatio.x * slidesPerPage,
    y: singleSlideAspectRatio.y,
    offsetX: desktopSingleSlideOffset * (slidesPerPage - 1),
  });

  useEffect(() => {
    if (infinite) {
      const newLoaded = [...loaded];
      newLoaded[nonEmptySlides.length - 1] = true;
      setLoaded(newLoaded);
    } else {
      const newLoaded = nonEmptySlides.map(() => true);
      setLoaded(newLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  useEffect(() => {
    const newLoaded = nonEmptySlides.map(() => true);

    setLoaded(newLoaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  return (
    <S.ImagesCarousel ref={ImagesCarouselRef} {...rest}>
      <Slider
        ref={carouselWrapperRef}
        showArrows={showArrows}
        absoluteArrows
        inverseLoadingCover
        slideOffset={slideOffset}
        infinite={infinite}
        autoPlay={autoPlay}
        skipLoader={skipLoader}
        slidesPerView={slidesPerView}
        disableBannerRounding={disableBannerRounding}
        loadingCoverAspectRatio={{
          mobile: singleSlideAspectRatio,
          tablet: tabletAspectRatio,
          desktop: desktopAspectRatio,
          hd: desktopAspectRatio,
        }}
        afterChange={(slider) => {
          const sliderDetails = slider.details();
          setCurrentSlide(sliderDetails.relativeSlide);
        }}
        slides={nonEmptySlides.map(({ title, slug, imgUrl, layout, hideTitle, imgAlt, onClick }, articleIdx) => {
          const key = `${articleIdx}_${slug}`;
          const slugWithoutSlash = slug.slice(1);
          const slideLinkProps = createNextLinkProps(slugWithoutSlash, layout, language);

          return (
            <S.LinkWrapper key={key} blank {...slideLinkProps} onClick={onClick}>
              <S.Article disableBannerRounding={disableBannerRounding}>
                <S.ArticleImg
                  src={!loaded[articleIdx] ? '/static/img/placeholder.webp' : imgUrl}
                  alt={imgAlt || title}
                  width={16}
                  height={9}
                  layout="responsive"
                  quality={85}
                  priority={articleIdx <= slidesPerView[currentBreakpoint]}
                />
                {!hideTitle && title && (
                  <S.ArticleDescription
                    isSinglePerPage={isSinglePerPage}
                    slidesPerView={slidesPerView[breakpointsKeys.DESKTOP]}
                  >
                    {title}
                  </S.ArticleDescription>
                )}
              </S.Article>
            </S.LinkWrapper>
          );
        })}
      />
    </S.ImagesCarousel>
  );
};

ImagesCarousel.defaultProps = defaultProps;
